<script setup lang="ts" inherit-attrs="false">
type Icon = 'bullet' | 'close' | 'back' | 'forward' | 'chevron' | ''

interface Props {
  text?: string
  leftIcon?: Icon
  rightIcon?: Icon
  nativeType?: Button.NativeType
  disabled?: boolean
  blinking?: boolean
  tag?: Button.Tag
  backgroundColor?: string
  textColor?: string
  borderRounded?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  text: '',
  nativeType: 'button',
  leftIcon: '',
  rightIcon: '',
  disabled: false,
  tag: 'button',
  backgroundColor: 'var(--gray)',
  textColor: 'var(--black)',
  borderRounded: true,
})

const attributes = useAttrs()

const computedTag = computed(() => {
  if (props.tag === 'nuxt-link')
    return resolveComponent('NuxtLink')
  if (attributes.disabled !== undefined && attributes.disabled !== 'false')
    return 'button'

  return props.tag
})
const hoverColor = computed(() => {
  if (props.backgroundColor === 'white') {
    return 'var(--gray)'
  }
  else if (props.backgroundColor === 'black') {
    return 'var(--dark-hover)'
  }
  else {
    return 'var(--gray-hover)'
  }
})
</script>

<template>
  <component
    :is="computedTag"
    v-bind="$attrs"
    class="button"
    :class="[{ rounded: borderRounded }]"
    :type="nativeType"
    :disabled="disabled"
    :style="{ '--bg-color': backgroundColor, '--text-color': textColor, '--hover-color': hoverColor, '--hover-text-color': props.textColor }"
  >
    <span
      v-if="leftIcon"
      class="icon"
      :class="blinking"
    >
      <span
        :style="{
          '-webkit-mask-image': `url('/icons/${leftIcon}.svg')`,
        }"
      />
    </span>

    <span
      v-if="text"
      class="text"
    >{{ text }}</span>

    <span
      v-if="rightIcon"
      class="icon"
    >
      <span
        :style="{
          '-webkit-mask-image': `url('/icons/${rightIcon}.svg')`,
        }"
      />
    </span>
  </component>
</template>

<style lang="scss" scoped>
@import 'assets/scss/typography/body';

.button {
  display: inline-flex;
  background-color: var(--bg-color);
  color: var(--text-color);
  align-items: center;
  gap: 0.7rem;
  padding: 1.2rem 1.6rem;

  &.rounded {
    border-radius: 0.2rem;
  }

  &:hover {
    background: var(--hover-color);
    color: var(--hover-text-color);
    & .icon span {
      background-color: var(--hover-text-color);
    }
  }

  span.text {
    @include body2;
    color: unset;
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.4rem;
    height: 2.4rem;

    span {
      width: 1rem;
      height: 1rem;
      display: block;
      mask-size: contain;
      mask-position: center;
      mask-repeat: no-repeat;
      background-color: var(--text-color);
    }
  }
}

.blinking {
  animation: 2s blink linear infinite;
}

@keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
}
</style>
